.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  
    background-color: rgb(3, 146, 108);
    border: 1px solid wheat;
    /* width: 100%;
    position: fixed; */
  }
  
  .title1 {
    color: white;
    font-weight: bold;
    margin-left: -5em;
    margin-top: -1px;
    margin-bottom: -0.5px;
    font-size: 40px;
  }
  .title2 {
    font-weight: 400;
  }
  
  .menus {
    display: flex;
    justify-content: space-around;
    margin-bottom: -10em;
  }
  
  .opciones {
    color: white;
    text-decoration: none;
  }
  
  .containerSearch {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;
  }
  
  .getRecipeId {
    display: flex;
    background-color: white;
    padding: 9px 22px 9px 26px;
    border-radius: 30px;
    border: 2px solid #cad3dc;
  }
  
  .inputSearchBar {
    border: none;
    background: transparent;
    font-weight: bold;
    padding-left: 24px;
    background-image: url(../../image/lupa.png);
    width: 0px;
    background-repeat: no-repeat;
    transition: 0.6s;
  }
  .inputSearchBar:focus {
    outline: none;
    width: 300px;
  }
  
  .button {
    border: none;
    background-color: rgb(0, 168, 0);
    border-radius: 12px;
  }
  .button:hover {
    background-color: rgb(5, 213, 5);
  }
  .button:active {
    background-color: rgb(131, 245, 131);
  }