.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 2em;
  align-items: center;
  align-self: auto;
}

.titulo {
  color: rgba(9, 163, 79, 0.877);
  font-family: Kavoon;
}




.image {
  margin: 1em;
  border-radius: 10px;
  max-width: 40em;
  max-height: 40em;
 
  border-radius:50%;
-webkit-border-radius:50%;
box-shadow: 0px 0px 15px 15px #ede1d7;
-webkit-box-shadow: 0px 0px 15px 15px #bfbcbb;
transform: rotate(360deg);
-webkit-transform: rotate(360deg);
}

.containerText {
  display: flex;
  flex-direction:center;
  margin: 2em;
  justify-content: flex-start;
}

.parraLargo {
  font-family: Itim;
  font-size: 20px;
  white-space: pre-line;
  text-align: left;

}

 