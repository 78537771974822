.containerRecipes {
  display: flex;
  grid-template-columns: auto 80%;
  flex-wrap: wrap;
}
.navBar {
  background: rgba(235, 229, 225, 0.69);
  /* background: rgba(178, 165, 157, 0.69); */
  border: 1px solid #8f8e8e;
  height: 50px;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 50;
}

.abscenter {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.form {
  width: 450px;
}
