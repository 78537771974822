.containerFilter {
    margin-top: 0.5em;
    display: grid; 
    box-shadow: 3px 3px 20px 3px rgba(203, 236, 220, 0.912);
    border-radius: 1px;
    min-height: 5vh;
    background-color: rgb(227, 244, 239);
  }
  
  .containerDiets {
    margin-left: 5px;
    margin-top: 5px;
  }
  .labelDiets {
    color: rgb(25, 100, 110);
    font-weight: bold;
    margin-left: 100px;
    font-size: 20px;

  }
  
  .diets {
    margin-top: 5px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Carter;
    color: rgb(17, 14, 14);
    margin-left: 100px;
   
  }

  
  .items {
    width: 300px;
    height: 40px;
    font-size: 20px;
    font-family: Carter;
    color: rgb(67, 127, 89);
  }
  