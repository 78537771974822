.card {
    border-radius: 20px;
    width: 330px;
    height: 400px;
    background-color: #c9c7bac6;
    color:rgb(10, 10, 10);
    margin: 0px 10px;
    box-shadow: 3px 3px 20px 3px rgba(87, 174, 174, 0.912);
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    
}
.abs-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
  
  .form {
    width: 450px;
  }
